// ** Polyfills
import ResizeObserver from "resize-observer-polyfill";

// ** React Imports
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// ** Third Party Components
import { useLayer, mergeRefs } from "react-laag";

// ** Dropdown Components
import TopSection from "./components/TopSection";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";
import { DropdownTriggerContext } from "./DropdownTriggerContext";

// ** Custom Hooks
import useMobileDetect from "hooks/useMobileDetectHook";

// ** Styled Components
import { borderRadiuses } from "styles/declares";
import {
	StyledDropdownLayerContainer,
	StyledBackdrop,
	StyledMotionDiv,
} from "./styles";

// #####################################################

const Dropdown = ({
	isOpen,
	close,
	useLayerOptions,
	customLayerStyle,
	withMobileVersion,
	children,
	mobileTitle,
	alwaysLight,
	minWidth,
	customMobileLayerStyle,
	isParentComponent,
	isKeyboardOpen,
	visualViewportHeight,
	isSingleDropdown,
	isMultiDropdown,
	menuContent,
	isEmojiModal = false,
	isLanding = false,
	autoBorder = false,
	autoBroderAllDevices = false,
}) => {
	const { isPhone } = useContext(deviceTypeContext);
	const mobileDetect = useMobileDetect();
	const isIos = mobileDetect.isIos();
	const isAndroid = mobileDetect.isAndroid();

	const isPhoneKeyboardOpen =
		isKeyboardOpen && isPhone && (isAndroid || isIos);

	const location = useLocation();

	const layerRef = useRef(null);

	// ** Hook useLayer - React Laag
	const { renderLayer, triggerProps, layerProps, triggerBounds, layerSide } =
		useLayer({
			onOutsideClick: () => {
				if (typeof close === "function") {
					close();
				}
			},
			onParentClose: () => {
				if (typeof close === "function") {
					close();
				}
			},
			isOpen,
			overflowContainer: true,
			auto: true,
			placement: "bottom-start",
			possiblePlacements: ["top-start", "bottom-start"],
			triggerOffset: -1,
			container: "app",
			ResizeObserver,
			...useLayerOptions,
		});

	// ** Style LayerPropsStyle - domyślne lub przekazane przez propsa 'customLayerStyle'.
	const layerPropsStyle = !isEmojiModal
		? {
				zIndex: 998,
				width: triggerBounds?.width || "auto",
				minWidth: minWidth
					? typeof minWidth === "string"
						? minWidth
						: typeof minWidth === "number"
						? `${minWidth}px`
						: "fit-content"
					: "unset",
				overflow: "hidden",
				overscrollBehaviorY: "contain",
				borderRadius: isLanding
					? `${borderRadiuses.landing}rem`
					: `${borderRadiuses.card}rem`,
				borderTopRightRadius: 0,
				borderTopLeftRadius: 0,
				...layerProps.style,
				...customLayerStyle,
		  }
		: {
				zIndex: 1000,
				width: triggerBounds?.width || "auto",
				minWidth: minWidth
					? typeof minWidth === "string"
						? minWidth
						: typeof minWidth === "number"
						? `${minWidth}px`
						: "fit-content"
					: "unset",
				overflow: "hidden",
				overscrollBehaviorY: "contain",
				borderRadius:
					layerSide === "top"
						? `${borderRadiuses.card}rem ${borderRadiuses.card}rem  0 ${borderRadiuses.card}rem`
						: `${borderRadiuses.card}rem 0 ${borderRadiuses.card}rem ${borderRadiuses.card}rem`,
				...layerProps.style,
		  };

	// ** Styl Finalny - Nadpisanie Stylu Użytkownika w Przypadku Gdy Korzystamy z Wersji Mobilnej
	const shortenBy = 48;

	const maxHeightOfParentComponent =
		isPhoneKeyboardOpen && visualViewportHeight > 0
			? `${visualViewportHeight - shortenBy}px `
			: "75vh";

	const maxHeightOfChildComponent =
		isPhoneKeyboardOpen && visualViewportHeight > 0
			? `${visualViewportHeight - shortenBy * 2}px `
			: "70vh";

	const layerStyleFinal =
		withMobileVersion && isPhone
			? {
					position: "fixed",
					left: "1rem",
					right: "1rem",
					minWidth: "292px",
					zIndex: isParentComponent ? 999 : 2000,
					maxHeight:
						isParentComponent ||
						(isSingleDropdown && !isMultiDropdown)
							? maxHeightOfParentComponent
							: maxHeightOfChildComponent,
					margin: "0 auto",
					overflow: "hidden",
					overscrollBehaviorY: "contain",
					borderRadius: `${borderRadiuses.card}rem`,
					...customMobileLayerStyle,
			  }
			: layerPropsStyle;

	if (withMobileVersion && isPhone && isPhoneKeyboardOpen) {
		layerStyleFinal.top = visualViewportHeight / 2;
		layerStyleFinal.transform = "translateY(-50%)";
	}

	if (
		layerSide === "top" &&
		((!isPhone && autoBorder) || autoBroderAllDevices)
	) {
		layerStyleFinal.borderBottom = "none";
		layerStyleFinal.borderBottomLeftRadius = "0px";
		layerStyleFinal.borderBottomRightRadius = "0px";
		layerStyleFinal.borderTopLeftRadius = isLanding
			? `${borderRadiuses.landing}rem`
			: `${borderRadiuses.card}rem`;
		layerStyleFinal.borderTopRightRadius = isLanding
			? `${borderRadiuses.landing}rem`
			: `${borderRadiuses.card}rem`;
	} else if (layerSide === "bottom" && !isPhone && autoBorder) {
		layerStyleFinal.borderTop = "none";
	}

	useEffect(() => {
		close({ insideUseEffect: true });
	}, [location.key]);

	// #####################################################

	return (
		<>
			{/* Funkcja Renderująca Aktywator */}
			<DropdownTriggerContext.Provider
				value={{
					triggerProps,
					layerSide,
					isOpenSelector: isOpen,
					layerWidth: layerRef.current?.clientWidth,
				}}
			>
				{children}
			</DropdownTriggerContext.Provider>

			{/* Funkcja Renderująca Dropdown Container */}
			{renderLayer(
				<>
					{/* Wyświetlanie ciemniejszego tła w czasie otwarcia dropdowna na urządzeniu mobilnym */}
					{isOpen && isPhone && withMobileVersion && (
						<StyledBackdrop
							className="backdrop"
							style={{
								zIndex: isParentComponent ? 999 : 1000,
							}}
							onClick={() => {
								if (typeof close === "function") {
									close();
								}
							}}
						/>
					)}
					{isOpen && (
						<StyledMotionDiv
							motiondivattribute={"motionDiv"}
							className={
								isLanding
									? `landingAlwaysLight ${
											isOpen && layerSide === "top"
												? "openToTop"
												: ""
									  }`
									: `${
											isOpen && layerSide === "top"
												? "openToTop"
												: ""
									  }`
							}
							isNotPhone={!isPhone}
							withMobileVersion={withMobileVersion && isPhone}
							isPhoneKeyboardOpen={isPhoneKeyboardOpen}
							alwaysLight={alwaysLight}
							innerRef={mergeRefs(layerRef, layerProps.ref)}
							style={layerStyleFinal}
							layerSide={layerSide}
							onClick={(e) => {
								const attributeName =
									e.target.getAttribute("motiondivattribute");

								if (
									typeof close === "function" &&
									attributeName === "motionDiv" &&
									!isParentComponent
								) {
									close();
								}
							}}
						>
							<StyledDropdownLayerContainer
								alwaysLight={alwaysLight}
								isSingleDropdown={isPhone && isSingleDropdown}
							>
								<TopSection
									alwaysLight={alwaysLight}
									mobileTitle={mobileTitle}
								/>
								{menuContent({ layerSide })}
							</StyledDropdownLayerContainer>
						</StyledMotionDiv>
					)}
				</>
			)}
		</>
	);
};

// #####################################################

Dropdown.defaultProps = {
	useLayerOptions: {},
	layerWidth: null,
	withMobileVersion: false,
	mobileTitle: null,
};

export default Dropdown;

// #####################################################
