// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledMotionDiv = styled(
	({
		isNotPhone,
		withMobileVersion,
		isPhoneKeyboardOpen,
		layerSide,
		innerRef,
		...props
	}) => <div ref={innerRef} {...props} />
)`
	background-color: var(--dz-sys-input-background-color);
	border: 1px solid var(--dz-sys-input-border-color-hover);
	border-radius: var(--dz-sys-card-border-radius);

	${({ withMobileVersion, isPhoneKeyboardOpen }) =>
		withMobileVersion &&
		(isPhoneKeyboardOpen
			? css`
					top: 0px;
					-webkit-transform: translate(0px, 0px);
					-moz-transform: translate(0px, 0px);
					-ms-transform: translate(0px, 0px);
					-o-transform: translate(0px, 0px);
					transform: translate(0px, 0px);
			  `
			: css`
					top: 50%;
					-webkit-transform: translate(0px, -50%);
					-moz-transform: translate(0px, -50%);
					-ms-transform: translate(0px, -50%);
					-o-transform: translate(0px, -50%);
					transform: translate(0px, -50%);
			  `)};

	${({ isNotPhone, layerSide }) =>
		isNotPhone &&
		(layerSide === "top"
			? css`
					@keyframes dropdownUp {
						0% {
							transform: translateY(10px);
						}
						50% {
							transform: translateY(0px);
						}
						100% {
							transform: translateY(0px);
						}
					}

					-webkit-animation: dropdownUp 0.3s linear forwards;
					-moz-animation: dropdownUp 0.3s linear forwards;
					-ms-animation: dropdownUp 0.3s linear forwards;
					-o-animation: dropdownUp 0.3s linear forwards;
					animation: dropdownUp 0.3s linear forwards;
			  `
			: css`
					@keyframes dropdownBottom {
						0% {
							transform: translateY(-10px);
						}
						50% {
							transform: translateY(0px);
						}
						100% {
							transform: translateY(0px);
						}
					}

					-webkit-animation: dropdownBottom 0.3s linear forwards;
					-moz-animation: dropdownBottom 0.3s linear forwards;
					-ms-animation: dropdownBottom 0.3s linear forwards;
					-o-animation: dropdownBottom 0.3s linear forwards;
					animation: dropdownBottom 0.3s linear forwards;
			  `)};

	${({ layerSide }) =>
		layerSide === "top"
			? css`
					box-shadow: ${({ theme }) =>
						theme.utils.createBoxShadow(["panelTop"])};
			  `
			: css`
					box-shadow: var(--dz-sys-input-shadow);
			  `};
`;

// #####################################################
