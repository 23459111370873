// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledDropdownLayerContainer = styled.div`
	display: flex;
	height: 100%;
	overflow: auto;
	flex-direction: column;

	${({ isSingleDropdown }) =>
		isSingleDropdown &&
		css`
			margin: auto 0;
			/* overflow: auto; - zakomentowane bo po 13 miesiącach zaczęło robić podwójny scrollbar na Chrome */
			overscroll-behavior-y: contain;
		`}

	${({ alwaysLight, isSingleDropdown }) =>
		alwaysLight && isSingleDropdown
			? css`
					background-color: var(--dz-ref-color-white);
			  `
			: isSingleDropdown &&
			  css`
					background-color: var(--dz-sys-input-background-color);
			  `}
`;

// #####################################################
