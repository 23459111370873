// ** React Imports
import { useContext } from "react";

// ** Custom Components
import Block from "components/layout/Block";
import Heading from "components/typography/Heading";
import Spacer from "components/layout/Spacer";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const TopSection = ({ mobileTitle, alwaysLight }) => {
	const { isPhone } = useContext(deviceTypeContext);

	const finalShowTitle = mobileTitle && isPhone;

	// #####################################################

	return finalShowTitle ? (
		<Block
			flex
			justifyBetween
			alignCenter
			style={{ padding: "0.75rem 1rem 0" }}
		>
			{finalShowTitle && (
				<Heading alwaysLight={alwaysLight} type="h5" bold>
					{mobileTitle}
				</Heading>
			)}
			<Spacer />
		</Block>
	) : null;
};

export default TopSection;

// #####################################################
