// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledBackdrop = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	position: fixed;
	z-index: 2;
`;

// #####################################################
