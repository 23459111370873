// ** React Imports
import { useContext, useState } from "react";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const useOpenClose = () => {
	const { isPhone } = useContext(deviceTypeContext);

	const [isOpen, setOpen] = useState(false);

	const open = (onOpenFn) => {
		setOpen(true);
		if (typeof onOpenFn === "function") {
			onOpenFn();
		}
	};

	const close = (onCloseFn) => {
		setOpen(false);
		if (typeof onCloseFn === "function") {
			onCloseFn();
		}
	};

	const toggle = (onToggleFn) => {
		setOpen(!isOpen);
		if (typeof onToggleFn === "function") {
			onToggleFn();
		}
	};

	const extendedClose = ({ insideUseEffect = false } = {}) => {
		if (insideUseEffect) {
			close();
		} else {
			close(() => {
				if (document.body.style.overflow === "hidden") {
					document.body.style.overflow = "unset";
				}
			});
		}
	};

	const extendedToggle = () => {
		toggle(() => {
			if (
				!isOpen &&
				isPhone &&
				document.body.style.overflow !== "hidden"
			) {
				document.body.style.overflow = "hidden";
			} else if (isOpen && document.body.style.overflow === "hidden") {
				document.body.style.overflow = "unset";
			}
		});
	};

	// #####################################################

	return {
		isOpen,
		open,
		close,
		toggle,
		extendedClose,
		extendedToggle,
	};
};

export default useOpenClose;

// #####################################################
